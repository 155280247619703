function NoPage() {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "5rem",
          flexDirection: "column",
        }}
      >
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </>
  );
}

export default NoPage;
